import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { Footer, Header } from './components';

export default function App(props) {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Header />

        <div className="App">
          <Router />
        </div>

        <Footer />
      </React.Fragment>
    </BrowserRouter>
  );
}
