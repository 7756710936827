import React from 'react';
import { NavLink } from '..';

export default function Header(props) {
  return (
    <nav className="navbar Header" role="navigation">
      <div className="navbar-brand">
        <NavLink to="/" className="navbar-item">
          <img src="/logo.png" alt="Home" />
        </NavLink>
      </div>

      <div className="navbar-menu">
        <div className="navbar-end">
          {/*<NavLink to="/marketing" className="navbar-item">
            Marketing
          </NavLink>*/}

          <NavLink to="/privacy" className="navbar-item">
            Privacy
          </NavLink>

          <NavLink to="/contact" className="navbar-item">
            Contact
          </NavLink>
        </div>
      </div>
    </nav>
  );
}
