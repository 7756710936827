import Loadable from 'react-loadable';
import { Loading } from '../components';
export { default as Home } from './Home/Home';

export const Contact = Loadable({
  loader: () => import('./Contact/Contact'),
  loading: Loading,
});

export const Marketing = Loadable({
  loader: () => import('./Marketing/Marketing'),
  loading: Loading,
});

export const Privacy = Loadable({
  loader: () => import('./Privacy/Privacy'),
  loading: Loading,
});
