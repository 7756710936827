import React from 'react';
import { NavLink } from '..';

export default function Footer(props) {
  return (
    <footer className="footer is-hidden-tablet">
      <div className="content">
        <p>
          <NavLink to="/privacy">
            Privacy
          </NavLink>{' · '}
          <NavLink to="/contact">
            Contact
          </NavLink>
        </p>
      </div>
    </footer>
  );
}
