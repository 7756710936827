import React from 'react';

export default function Home(props) {
  return (
    <div className="Home columns is-multiline is-mobile">
      <div className="column is-two-thirds-widescreen is-full-mobile">
        <h2 className="HeroTitle">Get ready for HSK for free</h2>
        <h1 className="HeroTitle">Less struggle, better results</h1>
        
        <a href="https://itunes.apple.com/us/app/hsk-exams/id1439774556" target="_blank" rel="noopener noreferrer">
          <img src="/img/dl-appstore.svg" className="ios-button" alt="Download on the App Store" />
        </a>
      </div>

      <div className="column is-full-mobile">
        <img src="/img/home.png" alt="" />
      </div>
    </div>
  );
}
