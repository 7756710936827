import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Contact, Home, Marketing, Privacy } from '../pages';

export default function Router(props) {
  return (
    <Switch>
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/marketing" component={Marketing} />
      <Route exact path="/privacy" component={Privacy} />
      <Route component={Home} />
    </Switch>
  );
}
